<template>
  <v-app>
    <v-main>
      <centered-layout>
        <div class="text-center">
          <v-progress-circular color="primary" class="mb-4" indeterminate />
          <h1 class="title">{{ $t('views.auth.processing') }}</h1>
        </div>
      </centered-layout>
    </v-main>
  </v-app>
</template>

<script>
import { ProcessMixin } from '@lib/vue-playrix-auth/mixins';

import CenteredLayout from './layouts/CenteredLayout.vue';

export default {
  metaInfo() {
    return {
      title: this.$t('views.auth.processing'),
    };
  },
  mounted() {
    this.processAuth().catch(() => {
      this.$toast.error(this.$t('views.auth.error'));

      this.$router.push({ name: 'auth' }).catch(() => {});
    });
  },
  components: { CenteredLayout },
  mixins: [ProcessMixin],
};
</script>
